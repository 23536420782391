.About{
    max-width: 1170px;
    margin: 182px auto;
}
.description{
    font-size: 33px;
    text-align: left;
    max-width: 1054px;
}

.photos{
    margin-top: 50px;
    display: flex;
}
.firstbox{
    max-width: 370px;
    min-height: 532px;
    background: #FBF5FF;
    border: 3px solid #9F277F;
    border-radius: 5px;
    padding:29px 34px 34px 40px ;
    margin-top: 40px;
    text-align: center;
    height: 550px;
}
.secondbox{
    max-width: 370px;
    min-height: 532px;
    background: #FBF5FF;
    border: 3px solid #9F277F;
    border-radius: 5px;
    text-align: center;
    padding:29px 34px 34px 40px ;
    margin-left: 30px;
    height: 550px;
}
.thirdbox{
    max-width: 370px;
    min-height: 532px;
    background: #FBF5FF;
    border: 3px solid #9F277F;
    border-radius: 5px;
    padding:29px 34px 34px 40px ;
    margin-left: 30px;
    margin-top: 40px;
    text-align: center;
    height: 550px;
}
.free{
    display: flex;
    justify-content: end;
}
.one{
    display: flex;
}
.download1{
    margin-top: -36px;
    text-align: center;
}
.titeles{
    margin-top: 20px;
    margin-left: 10px;
}
.titel{
    font-size: 17px;
}
.uptitle{
    font-size: 12px;
    text-align: left;
}
.uptitle3{
    font-size: 12px;
    text-align: left;
    width: 163px;
}
.picture{
    margin-top: 35px;
    width: 165px;
}
.download{
    background: #000000;
    border-radius: 5px;
    Width:276px;
    Height:30px;
    color: white;
    font-size: 17px;
    padding: 10px 0 15px 0;
    text-align: center;
    box-shadow: 5px -7px 0px 1px #9F277F;
    margin: 47px auto;
    cursor: pointer;
}
@media only screen and (max-width: 1280px){
    .photos {
        margin-top: 50px;
        display: block;
    }
    .firstbox {
        margin: 100px auto 0;
    }
    .secondbox {
        margin: 46px auto;
    }
    .thirdbox {
        margin: 46px auto;
        height: 575px;
    }
    .About {
        max-width: 699px;
        margin: 182px auto;
        line-height: 24px;
    }

}
    @media only screen and (max-width: 650px){
        .why {
            font-size: 25px;
            text-align: center;
            margin-top: 120px;
            width: 354px;
            margin: 120px auto;
        }
        .group2 {
            display: flex;
            margin: 0 auto;
            width: 357px;
        }
        .imgCertificate {
            height: 250px;
        }
        .description {
            font-size: 25px;
        }
        .About {
            max-width: 699px;
            margin: 182px auto;
            line-height: 24px;
            padding: 0 10px;
        }
        .firstbox {
            max-width: 270px;
            min-height: 532px;
            background: #FBF5FF;
            border: 3px solid #9F277F;
            border-radius: 5px;
            padding: 29px 34px 34px 40px;
            margin-top: 40px;
            text-align: center;
            height: 550px;
        }.secondbox {
            max-width: 270px;
        }
        .thirdbox {
            max-width: 270px;
            height: 580px;
        }
    }