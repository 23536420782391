
      @font-face {
        font-family: "Montserrat arm";
        src: url("../src/Fonts/Montserratarm-Regular.otf");
      }
body{
    font-family: 'Montserrat arm';
    
    
}
.footer{
    font-style: normal;
    font-weight: 400;
    font-size: 17px;
    line-height: 21px;color: #FFFFFF;
    height: 50px;
    padding: 38px 0 0 0;
    text-align: center;
    align-items: center;
    background: black;
    margin-bottom: 0 ;
}
.print_btn{
  position: fixed;
  top: 10px;
  right: 10px;
  width: 70px;
  height: 40px;
  border-radius: 10px;
  border: 1.5px solid #9c2190;
  color: #9c2190;
  background-color: transparent;
  font-size: 15px;
  font-weight: 400;
  transition: all .3s linear;
}
.print_btn:hover{

  width: 70px;
  height: 40px;
  border-radius: 10px;
  border: 3px solid #9c2190;
  color: #fac1f0;
  background-color: #9c2190;
  font-size: 15px;
  font-weight: 400;
}

#bajin_1, #bajin_2, #bajin_3, #bajin_4, #bajin_5{ color: #9F277F;
  text-shadow: 1px 2px 3px rgb(67, 11, 63)}
  
#text_1, #text_2, #text_3, #text_4, #text_5, .bajin2_text, .bajin3_text, .bajin4_text,.bajin5_text{
  color: #9f278d;
font-family:'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}
table{
  border: 1px solid black; 
text-align: center;}
 td,th{border: 1px solid black;
  text-align: center;}
ul>li{ list-style-type: square;;}
.text_2_1{
  color: #340827;
}
.text_col{
  color: #9c2190;
}
.scope{
  border: 2px solid #360226;
  border-radius: 20px;
  padding: 5px;
}
.scope2{
  border: 2px solid #360226;
  padding: 5px;
}
table .SZI_gcapatker>tr{padding: 20px;}

.ser_table{
  width: 1%;
}