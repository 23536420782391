ol, ul {
	list-style: none;
}
ul li a {
    text-decoration: none;
    color: black;
}
li:hover>a{
    color: rgb(157, 16, 147);
    text-shadow: 2px 2px 10px #7e087e;
    font-size: 17px;
}
