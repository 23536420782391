.Free{
    font-family: sans-serif;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width:100%;
    margin: 0 auto;
    max-width: 1170px;
}
.name{
    font-size: 72px;
    text-align: center;
}
.citizen{
    color: #9F277F; 
}
.modul{
    text-align: center;
    margin: auto;
    font-size: 28px;
    width: 40%;
}
.friends{
  text-align: center;
  margin-top: 50px;
}
.start {
    box-shadow: 5px -7px 0px 1px #000000;
    background: #9F277F;
    border-radius: 5px;
    color: white;
    padding: 20px 0 20px 30px;
    width:275px ;
    margin-top: 7px;
    cursor: pointer;
    font-size: 20px;
  }

  .slack{
    width: 100%;
    display: flex;
    align-items: center;
  }
  .for_logos{
   display: flex;
   justify-content: center;
   align-items: center;
   column-gap: 35px;
   margin-top: 40px; 
   
  }
  .newLogo{
    width: 180px;
    height: 60px;
    
  }
  #newLogo1{
    width: 30.5%;
    height: 130px;
   }
  .itLogo{
    width: 100px;
    height: 95px;
    
  }
  .right{
    width: 50%;
    border: 3px solid black;
    box-shadow: 5px -7px 0px 1px #000000;
    border-radius: 5px;
    margin-top: 50px;
  }

  .right-main{
    padding: 48px 24px;
  }

  .left{
    width: 100%;
  }
  .create{
    font-size: 24px;
    text-align: center;
  }

.icons-block{
  font-size: 24px;
  border: 3px solid rgb(83, 78, 78);
  border-radius:5px;
  height: 29px;
  cursor: pointer;
  grid-gap: 8px;
  padding: 20px 16px;
  display: flex;
}

  .GLF{
    display: flex;
    margin-top: 33px;
    justify-content: space-between;
  }

  .dashed {
    text-align: center;
    border-bottom: 2px solid transparent;
    height: 8px;
    border-image: repeating-linear-gradient( to right, #9F277F, #9F277F 5px, #fff 6px, #fff 15px) 25;
    margin-top: 15px;
}

  .dashed span {
    background: #fff;
    padding: 0 35px;
  }

.input{
    padding: 25px 16px;
    border: 3px solid black;
    font-size: 20px;
    margin-top: 12px;
    border-radius:5px;
}
.mail{
    width: 100%;
    display: flex;
    flex-direction: column;
}
.mailru{
    margin-top: 40px;
    font-size: 18px;
    margin-left: 15px;
}
.button{
    font-size: 20px;
    background: black;
    color: white;
    text-align: center;
    padding: 26px 0;
    margin-top: 60px;
    cursor: pointer;
    border-radius: 5px;
}


@media only screen and (max-width: 1366px) {
  .GLF{
    flex-direction: column;
  }
  .icons-block{
    margin-bottom: 12px;
  }

  .name{
    font-size: 50px;
  }
  .modul{
    font-size: 28px;
  }
.zigzag{
  width: 156px;
}

@media only screen and (max-width:1280px){
  .Free{
    flex-direction: column;
  }
}

}

@media only screen and (max-width: 900px) {
.name {
  font-size: 50px;
  margin: 51px auto 41px;
  text-align: center;
}
.modul {
  font-size: 20px;
  margin: 20px auto;
  text-align: center;
  width: auto;
}
.slack {
  display: flex;
  justify-content: center;
}
.zigzag {
  width: 156px;
  display: none;
}
.friends{
  display: flex;
  justify-content: center;
}
.right {
  width: 80%;
  border: 3px solid black;
  box-shadow: 5px -7px 0px 1px #000000;
  border-radius: 5px;
  margin-top: 50px;
}

}
@media screen and (max-width: 750px){
 

  .newLogo{
   width: 150px;
   height: 50px;
   
 }
 .itLogo{
   width: 80px;
   height: 85px;
   
 }
}
@media only screen and (max-width: 650px){
  .name {
    font-size: 35px;
    margin: 51px auto 41px;
    text-align: center;
}
.start{
  padding: 20px 30px;
  white-space: pre;
}
.slack{
  width: auto;
}
.modul {
  font-size: 17px;
  margin: 20px auto;
  text-align: center;
}
.friendss{
  width: 100%;
}
.newLogo{
  width: 120px;
  height: 40px;
  
}
.itLogo{
  width: 70px;
  height: 75px;
  
}

}
@media screen and (max-width: 600px){
 

  .for_logos{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    row-gap: 0px;
    margin-top: 50px; 
   }
 
   .newLogo{
    width: 30%;
    height: 45px;
    
  }

  .itLogo{
    width: 20%;
    height: 80px;
    
  }
}