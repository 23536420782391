.Learn{
    font-family: sans-serif;
    display: flex;
    justify-content: space-between;
    grid-gap: 20px;
    width: 100%;
    margin: 158px auto;
    max-width: 1170px
}
.questions{
    font-size: 33px;
}
.questions1{
    color: #9F277F;
}
.paragraph1{
    font-size: 20px;
}
.video, .about{
    width: 50%;
}

@media only screen and (max-width:1280px){
    .Learn{
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
}

@media only screen and (max-width:900px){
    .Learn{
        width: 80%;
    }
    .video, .about{
        width: 100%;
    }
}
