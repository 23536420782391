.Certificate{
    display: flex;
    justify-content: center;
    background-color: #FCECFF;
}

.certificate-main{
    max-width: 1170px;
    margin: 0 auto;
}

.imgCertificate{
    width: 100%;
}

.why{
    font-size: 33px;
    text-align: center;
    margin-top: 120px;
}
.purple{
    color: #9F277F;
}
.group{
    display: flex;
    text-align: center;
    margin-top: 107px;
    width: 100%;
}
.groups{
    display: flex;
}

.groupa{
    height: 385px;
    margin-top: 242px;
}
.collect{
    font-size: 33px;
}
.collect1{
    font-size: 20px;
    width: 350px;
}
.black{
    display: flex;
    margin-top: 211px;
    justify-content: space-between;
}

.collectbig{
    display: flex;
    margin: auto;
}

.Rectangle{
    width: 50%;
}
.group2{
    display: none;
}
@media only screen and (max-width: 1366px) {
    .groupa {
        height: 203px;
        margin-left: -51px;
        margin-top: 490px;
    }
    .why {
        font-size: 33px;
        text-align: center;
        margin-top: 120px;
        width: 585px;
        margin: 120px auto;
    }
}
@media only screen and (max-width: 900px) {

    .why {
        font-size: 33px;
        text-align: center;
        margin-top: 120px;
        width: 585px;
        margin: 120px auto;
    }
    
.group{
    display: none;
}

.group2{
    display: flex;
    margin: 0 auto;
}
.groupa {
    width: 50px;
    height: 203px;
    margin-left: -85px;
    margin-top: 490px;
    display: none;
}

.Certificate{
    height: auto;
    max-width: 1920px;
    margin-top: 110px;
    display: flex;
    justify-content: center;
    background-repeat: round;
}
.black {
    display: flex;
    max-width: 1170px;
    margin: 0 auto;
    margin-top: 211px;
    flex-direction: column;
    grid-gap: 15px;
}
.Rectangle {
    margin: 20px auto;
}
.collectbig{
    margin: 0 auto;
    text-align: center;
    display: block;
}
.Rectangle{
    width: 80%;
}
}