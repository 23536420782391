.sit{
    margin-top: 31px;
    font-size: 17px;
    font-weight: 400;    
    
}
.tags{
    margin-left: 20px;
    margin-top: -3px;
}
.connect{
    font-size: 33px;
}
.subConnect{
    font-size: 17px;
    line-height: 20.72px;
}
.ConnectLeft{
    width: 40%;
}
.ConnectRight div{
    display: flex;
    align-items: center;
    row-gap: 0px;
    flex-direction: column;
}
.ConnectUs{
    border-top: 3px solid #9F277F;
   
    border-radius: 40px;
    padding-top: 50px;
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    margin-bottom: 100px;
    
  
}

.nameConnect{
    opacity: 0.7;
border: 2px solid #000000;
border-radius: 5px;
width: 240px;
font-size: 33px;
height: 70px;

}
.namenumber{
    display: flex;
}
.numberConnect{
    opacity: 0.7;
    border: 2px solid #000000;
    border-radius: 5px;
    width: 360px;
    height: 70px;
    
font-size: 33px;


}
.paragraphconnect{
    font-style: normal;
font-weight: 500;
font-size: 17px;
line-height: 21px;
}
.aboutblocktwo{
     margin-left: 30px;
}
.mailConnect{
    opacity: 0.7;
border: 2px solid #b8b7b7;
border-radius: 5px;

width: 645px;
font-size: 33px;
height: 70px;
}
.massageConnect{
    opacity: 0.7;
border: 2px solid #000000;
border-radius: 5px;
width: 645px;
font-size: 23px;

height: 118.65px;
}
.aboutblocktree{
    margin-top: 30px;
}
.aboutblockfour{
    margin-top: 30px;
}
.connectUsButton{
        box-shadow: 5px -7px 0px 1px black;
        background: #9F277F;
        border-radius: 5px;
        color: white;
        padding: 16px 0 20px 5px;
        height: 59px;
        width: 173px;
        margin-top: 7px;
        cursor: pointer;
        font-size: 20px;
        margin-top: 62px;
}
.contact_text{
    font-size: 25px;
    font-weight: 500;
}
.inp{
    
        border: 2px solid rgb(37, 37, 37);
        border-radius: 5px;
        font-size: 25px;
        height: 40px;
        opacity: 1;
        padding: 0 15px;
        width: 340px;
        margin-bottom: 18px;
}
.text_area{
    border: 2px solid rgb(37, 37, 37);
    border-radius: 5px;
    font-size: 18px;
    
    opacity: 1;
    padding: 0 15px;
    width: 340px;
    margin-bottom: 15px;
}

.btn{
    margin: auto;
    text-align: center;
    background: #9f277f;
    border-radius: 5px;
    box-shadow: 5px -7px 0 1px #000;
    color: #fff;
    cursor: pointer;
    font-size: 20px;
    height: 59px;
    margin-top: 15px;
    padding: 16px 0 20px 5px;
    width: 173px;
    transition: all .5s linear;
}
.btn:hover{
    background: #f8a5e2;
    color: #9F277F;
    box-shadow: 5px -7px 0 1px #000;
    
    cursor: pointer;
    font-size: 20px;
}

@media only screen and (max-width: 1280px) {
    .ConnectUs{
        flex-direction: column;
        align-items: center;
    }
}

@media only screen and (max-width: 900px) {
    .ConnectLeft {
        width: 603px;
        margin: 0 auto;
    }
    .ConnectRight{
        margin: 0 auto;
        
    }.ConnectUs {
        display: flex;
        justify-content: space-evenly;
        flex-direction: column;
        margin-bottom: 142px;
    }
    .sentconnect{
        display: flex;
        justify-content: end;
    }
}
@media only screen and (max-width: 650px){
    .connect {
        font-size: 25px;
    }
    .ConnectLeft {
        width: 356px;
        margin: 0 auto;
        margin-bottom: 50px;
        /* padding: 0 10px; */
    }
    
    .namenumber {
        display: block;
    }
    .nameConnect {
        opacity: 0.7;
        border: 2px solid #000000;
        border-radius: 5px;
        width: 348px;
        font-size: 33px;
        height: 70px;
        padding: 0 15px;
        margin: 0 0 0 10px;
    }
    .aboutblocktwo {
        margin-left: 10px;
    }
    .numberConnect {
        opacity: 0.7;
        border: 2px solid #000000;
        border-radius: 5px;
        width: 347px;
        height: 74px;
        font-size: 33px;
        padding: 0 15px;
    }
    .aboutblocktree {
        margin-top: 30px;
        margin: 30px 0 0 10px;
    }
    .mailConnect {
        opacity: 0.7;
        border: 2px solid #000000;
        border-radius: 5px;
        padding: 0 15px;
        width: 349px;
        font-size: 33px;
        height: 70px;
    }
    .aboutblockfour {
        margin-top: 30px;
        margin-left: 10px;
    }
    .massageConnect {
        opacity: 0.7;
        border: 2px solid #000000;
        border-radius: 5px;
        width: 349px;
        font-size: 23px;
        padding: 0 15px;
        height: 118.65px;
    }


    .input {
        padding: 25px 48px 25px 20px;
        border: 3px solid black;
        font-size: 15px;
        margin-top: 12px;
        border-radius: 5px;
    }

    .slack {
        margin: 0 auto;
    }
}
