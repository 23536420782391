.img{
  display: flex;
  justify-content: center;
  width: 100%;
}
.Logo{
    width: 130px;
    height: 130px;
    margin-top: 30px;
  }.Header {
    display: flex;
    justify-content: space-between;
    font-family: sans-serif;
    margin: 0 auto;
    width: 100%;
    max-width: 1170px;
}
  .login{
    display: flex;
    margin-top: 70px;
    font-size: 17px;
  }

  
  
 

  @media only screen and (max-width: 428px) {
    .signin{
      padding: 0 20px 0 0;
    }
    .language{
      padding: 0 0 0 20px;
    } 
  }